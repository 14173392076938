*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

.wrapper
{
  height: 80px;
}

.menu-container
{
  display: flex;
  flex-wrap: wrap;
}